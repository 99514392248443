<template>
  <div class="ai-drawer-options">
    <el-popover placement="right" v-model="displayPopover" width="350">
      <div
        v-if="displayFileImportOption"
        @click="
          handleClick('generate-content', {
            target: 'brain-import',
          })
        "
        class="ai-drawer-options__button"
      >
        <font-awesome-icon :icon="['fal', 'sync-alt']" />
        <span>{{
          $t(
            'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.brain-file-import',
          )
        }}</span>
      </div>
      <div
        v-if="displayGenerateOpenPrompt"
        @click="handleClick('generate-open-prompt')"
        class="ai-drawer-options__button"
      >
        <font-awesome-icon :icon="['fal', 'comments']" />
        <span>{{
          $t(
            'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.generate-open-prompt',
          )
        }}</span>
      </div>
      <div
        v-if="displayFilesMergeOption"
        @click="
          handleClick('generate-content', {
            target: 'brain-files-merge',
          })
        "
        class="ai-drawer-options__button"
      >
        <font-awesome-icon :icon="['fal', 'code-merge']" />
        <span>{{
          $t(
            'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.brain-files-merge',
          )
        }}</span>
      </div>
      <div v-if="bodyLength >= minimumContentSize">
        <div
          @click="
            handleClick('generate-content', {
              target: 'knowledge-manager',
            })
          "
          class="ai-drawer-options__button"
          v-if="displayKnowledgeManagerOption"
        >
          <font-awesome-icon :icon="['fal', 'user']" />
          <span>{{
            hasSummary || hasShortSummary
              ? $t(
                  'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-knowledge-manager',
                )
              : $t(
                  'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.knowledge-manager',
                )
          }}</span>
        </div>
        <div
          :class="[
            'ai-drawer-options__button justify-between',
            { expanded: openCollapse },
          ]"
          @click="openCollapse = !openCollapse"
          v-if="displaySummaryOptions"
        >
          <div class="items-center flex gap-2">
            <font-awesome-icon :icon="['fal', 'file-edit']" />
            <span>{{
              hasSummary || hasShortSummary
                ? $t(
                    'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-summaries',
                  )
                : $t(
                    'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.summaries',
                  )
            }}</span>
          </div>
          <font-awesome-icon :icon="['fal', 'chevron-right']" />
        </div>
        <div
          :class="['ai-drawer-options__summaries', { expanded: openCollapse }]"
          v-if="displaySummaryOptions"
        >
          <div
            @click="
              handleClick('generate-content', {
                target: 'summary',
                newGeneration: !hasSummary,
              })
            "
            :class="[
              'ai-drawer-options__button',
              {
                disabled:
                  generatingSummary || (aiDrawerTab === 'summary' && isActive),
              },
            ]"
          >
            <font-awesome-icon
              v-if="generatingSummary"
              :icon="['fal', 'spinner-third']"
              spin
            />
            <font-awesome-icon v-else :icon="['fal', 'file-alt']" />
            <span>{{
              hasSummary
                ? $t(
                    'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-summary',
                  )
                : $t(
                    'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.summary',
                  )
            }}</span>
          </div>
          <div
            @click="
              handleClick('generate-content', {
                target: 'short-summary',
                newGeneration: !hasShortSummary,
              })
            "
            :class="[
              'ai-drawer-options__button',
              {
                disabled:
                  generatingShortSummary ||
                  (aiDrawerTab === 'short-summary' && isActive),
              },
            ]"
          >
            <font-awesome-icon
              v-if="generatingShortSummary"
              :icon="['fal', 'spinner-third']"
              spin
            />
            <font-awesome-icon v-else :icon="['fal', 'file-minus']" />
            <span>{{
              hasShortSummary
                ? $t(
                    'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-short-summary',
                  )
                : $t(
                    'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.short-summary',
                  )
            }}</span>
          </div>
        </div>
        <div
          @click="
            handleClick('generate-content', {
              target: 'action-items',
              newGeneration: !hasActionItems,
            })
          "
          :class="[
            'ai-drawer-options__button',
            {
              disabled:
                generatingActionItems ||
                (aiDrawerTab === 'action-items' && isActive),
            },
          ]"
          v-if="displayActionItemsOption"
        >
          <font-awesome-icon
            v-if="generatingActionItems"
            :icon="['fal', 'spinner-third']"
            spin
          />
          <font-awesome-icon v-else :icon="['fal', 'file-check']" />
          <span>{{
            hasActionItems
              ? $t(
                  'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-action-items',
                )
              : $t(
                  'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.action-items',
                )
          }}</span>
          <el-tooltip
            :content="
              $t(
                'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.action-items-tooltip',
              )
            "
          >
            <font-awesome-icon :icon="['fal', 'question-circle']" />
          </el-tooltip>
        </div>
        <div
          @click="handleClick('generate-guide')"
          :class="[
            'ai-drawer-options__button',
            {
              disabled: generatingGuide,
            },
          ]"
          v-if="displayArticleToGuideOption"
        >
          <font-awesome-icon
            v-if="generatingGuide"
            :icon="['fal', 'spinner-third']"
            spin
          />
          <font-awesome-icon v-else :icon="['fal', 'sync-alt']" />
          <span>{{
            $t(
              'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.article-to-guide',
            )
          }}</span>
        </div>
      </div>
      <div v-else class="ai-infos">
        <font-awesome-icon :icon="['fal', 'info-circle']" class="mr-2" />
        <span>{{
          $t(
            'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.empty-body',
            { length: minimumContentSize - bodyLength },
          )
        }}</span>
      </div>
      <side-bar-item
        slot="reference"
        :description="tooltipDescription"
        :type="type"
        :ai-feature="true"
      >
        <template v-slot:icon="{ _class }">
          <font-awesome-icon
            slot="icon"
            :class="[
              _class,
              {
                active: open || isActive,
              },
            ]"
            :icon="['fal', 'sparkles']"
          />
        </template>
      </side-bar-item>
    </el-popover>
  </div>
</template>

<script>
import SideBarItem from 'views/KnowledgeEditor/KnowledgeEditorMain/BuilderSibeBar/SideBarItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AiDrawerOption',
  components: { SideBarItem },
  data: () => ({
    open: false,
    displayPopover: false,
    openCollapse: false,
  }),
  props: {
    type: {
      type: String,
      default: () => 'diagnostic',
    },
    generatingSummary: {
      type: Boolean,
      default: false,
    },
    generatingShortSummary: {
      type: Boolean,
      default: false,
    },
    generatingActionItems: {
      type: Boolean,
      default: false,
    },
    generatingGuide: {
      type: Boolean,
      default: false,
    },
    aiDrawerTab: {
      type: String,
      default: null,
    },
    bodyLength: {
      type: Number,
      default: 0,
    },
    summary: {
      type: Object,
      default: () => ({}),
    },
    shortSummary: {
      type: Object,
      default: () => ({}),
    },
    kmRecommendations: {
      type: Object,
      default: () => ({}),
    },
    actionItems: {
      type: Object,
      default: () => ({}),
    },
    minimumContentSize: {
      type: Number,
      default: 300,
    },
  },
  computed: {
    tooltipDescription() {
      return this.$t('knowledge.diagnostic-builder.side-bar.ai-drawer.tooltip');
    },
    isActive() {
      return this.open || this.hasSummary || this.hasShortSummary;
    },
    hasSummary() {
      return (
        !!this.summary && !!this.summary.body && !!this.summary.body.length
      );
    },
    hasShortSummary() {
      return (
        !!this.shortSummary &&
        !!this.shortSummary.body &&
        !!this.shortSummary.body.length
      );
    },
    hasActionItems() {
      return (
        !!this.actionItems &&
        !!this.actionItems.body &&
        !!this.actionItems.body.length
      );
    },
    hasKMRecommendations() {
      return !!Object.keys(this.kmRecommendations).length;
    },
    displaySummaryOptions() {
      return this.type === 'article' && this.companyAllowSummaryPreference;
    },
    displayKnowledgeManagerOption() {
      return (
        this.type === 'article' && this.companyAllowKnowledgeManagerPreference
      );
    },
    displayActionItemsOption() {
      return this.type === 'article' && this.companyAllowActionItemsPreference;
    },
    displayArticleToGuideOption() {
      return this.type === 'article' && this.companyAllowDecisionTreePreference;
    },
    displayFileImportOption() {
      return this.companyAllowFileImportPreference;
    },
    displayFilesMergeOption() {
      return this.type === 'article' && this.companyAllowFilesMergePreference;
    },
    displayGenerateOpenPrompt() {
      return this.type === 'article' && this.companyAllowGenerateOpenPrompt;
    },
    ...mapGetters([
      'companyAllowSummaryPreference',
      'companyAllowActionItemsPreference',
      'companyAllowDecisionTreePreference',
      'companyAllowKnowledgeManagerPreference',
      'companyAllowFileImportPreference',
      'companyAllowFilesMergePreference',
      'companyAllowGenerateOpenPrompt',
    ]),
  },
  methods: {
    handleClick(eventName, target, payload) {
      this.displayPopover = false;
      this.openCollapse = false;
      this.$emit(eventName, target, payload);
    },
  },
};
</script>

<style scoped lang="scss">
.ai-drawer-options__button {
  color: $purple-9-mayday;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  &:hover {
    color: $purple-5-mayday;
  }
  &.disabled {
    pointer-events: none;
    color: $grey-3-mayday;
  }
  .fa-chevron-right {
    transition: transform 0.2s ease-in-out;
  }

  &.expanded {
    .fa-chevron-right {
      transform: rotate(90deg);
    }
  }
}

.ai-infos {
  background-color: rgba($purple-5-mayday, 0.2);
  border-radius: 8px;
  overflow: hidden;
  padding: 8px 12px;
  font-size: 12px;
  width: 100%;
}

.ai-drawer-options__summaries {
  padding-left: 20px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-in-out;
  .ai-drawer-options__button {
    font-weight: 400;
  }
  &.expanded {
    max-height: 150px;
  }
}
</style>
