var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ai-drawer-options"},[_c('el-popover',{attrs:{"placement":"right","width":"350"},model:{value:(_vm.displayPopover),callback:function ($$v) {_vm.displayPopover=$$v},expression:"displayPopover"}},[(_vm.displayFileImportOption)?_c('div',{staticClass:"ai-drawer-options__button",on:{"click":function($event){return _vm.handleClick('generate-content', {
          target: 'brain-import',
        })}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync-alt']}}),_c('span',[_vm._v(_vm._s(_vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.brain-file-import', )))])],1):_vm._e(),(_vm.displayGenerateOpenPrompt)?_c('div',{staticClass:"ai-drawer-options__button",on:{"click":function($event){return _vm.handleClick('generate-open-prompt')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'comments']}}),_c('span',[_vm._v(_vm._s(_vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.generate-open-prompt', )))])],1):_vm._e(),(_vm.displayFilesMergeOption)?_c('div',{staticClass:"ai-drawer-options__button",on:{"click":function($event){return _vm.handleClick('generate-content', {
          target: 'brain-files-merge',
        })}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'code-merge']}}),_c('span',[_vm._v(_vm._s(_vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.brain-files-merge', )))])],1):_vm._e(),(_vm.bodyLength >= _vm.minimumContentSize)?_c('div',[(_vm.displayKnowledgeManagerOption)?_c('div',{staticClass:"ai-drawer-options__button",on:{"click":function($event){return _vm.handleClick('generate-content', {
            target: 'knowledge-manager',
          })}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'user']}}),_c('span',[_vm._v(_vm._s(_vm.hasSummary || _vm.hasShortSummary ? _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-knowledge-manager', ) : _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.knowledge-manager', )))])],1):_vm._e(),(_vm.displaySummaryOptions)?_c('div',{class:[
          'ai-drawer-options__button justify-between',
          { expanded: _vm.openCollapse },
        ],on:{"click":function($event){_vm.openCollapse = !_vm.openCollapse}}},[_c('div',{staticClass:"items-center flex gap-2"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'file-edit']}}),_c('span',[_vm._v(_vm._s(_vm.hasSummary || _vm.hasShortSummary ? _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-summaries', ) : _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.summaries', )))])],1),_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-right']}})],1):_vm._e(),(_vm.displaySummaryOptions)?_c('div',{class:['ai-drawer-options__summaries', { expanded: _vm.openCollapse }]},[_c('div',{class:[
            'ai-drawer-options__button',
            {
              disabled:
                _vm.generatingSummary || (_vm.aiDrawerTab === 'summary' && _vm.isActive),
            },
          ],on:{"click":function($event){return _vm.handleClick('generate-content', {
              target: 'summary',
              newGeneration: !_vm.hasSummary,
            })}}},[(_vm.generatingSummary)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner-third'],"spin":""}}):_c('font-awesome-icon',{attrs:{"icon":['fal', 'file-alt']}}),_c('span',[_vm._v(_vm._s(_vm.hasSummary ? _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-summary', ) : _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.summary', )))])],1),_c('div',{class:[
            'ai-drawer-options__button',
            {
              disabled:
                _vm.generatingShortSummary ||
                (_vm.aiDrawerTab === 'short-summary' && _vm.isActive),
            },
          ],on:{"click":function($event){return _vm.handleClick('generate-content', {
              target: 'short-summary',
              newGeneration: !_vm.hasShortSummary,
            })}}},[(_vm.generatingShortSummary)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner-third'],"spin":""}}):_c('font-awesome-icon',{attrs:{"icon":['fal', 'file-minus']}}),_c('span',[_vm._v(_vm._s(_vm.hasShortSummary ? _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-short-summary', ) : _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.short-summary', )))])],1)]):_vm._e(),(_vm.displayActionItemsOption)?_c('div',{class:[
          'ai-drawer-options__button',
          {
            disabled:
              _vm.generatingActionItems ||
              (_vm.aiDrawerTab === 'action-items' && _vm.isActive),
          },
        ],on:{"click":function($event){return _vm.handleClick('generate-content', {
            target: 'action-items',
            newGeneration: !_vm.hasActionItems,
          })}}},[(_vm.generatingActionItems)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner-third'],"spin":""}}):_c('font-awesome-icon',{attrs:{"icon":['fal', 'file-check']}}),_c('span',[_vm._v(_vm._s(_vm.hasActionItems ? _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.display-action-items', ) : _vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.action-items', )))]),_c('el-tooltip',{attrs:{"content":_vm.$t(
              'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.action-items-tooltip',
            )}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'question-circle']}})],1)],1):_vm._e(),(_vm.displayArticleToGuideOption)?_c('div',{class:[
          'ai-drawer-options__button',
          {
            disabled: _vm.generatingGuide,
          },
        ],on:{"click":function($event){return _vm.handleClick('generate-guide')}}},[(_vm.generatingGuide)?_c('font-awesome-icon',{attrs:{"icon":['fal', 'spinner-third'],"spin":""}}):_c('font-awesome-icon',{attrs:{"icon":['fal', 'sync-alt']}}),_c('span',[_vm._v(_vm._s(_vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.article-to-guide', )))])],1):_vm._e()]):_c('div',{staticClass:"ai-infos"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'info-circle']}}),_c('span',[_vm._v(_vm._s(_vm.$t( 'knowledge.diagnostic-builder.side-bar.ai-drawer.popover-labels.empty-body', { length: _vm.minimumContentSize - _vm.bodyLength }, )))])],1),_c('side-bar-item',{attrs:{"slot":"reference","description":_vm.tooltipDescription,"type":_vm.type,"ai-feature":true},slot:"reference",scopedSlots:_vm._u([{key:"icon",fn:function({ _class }){return [_c('font-awesome-icon',{class:[
            _class,
            {
              active: _vm.open || _vm.isActive,
            },
          ],attrs:{"slot":"icon","icon":['fal', 'sparkles']},slot:"icon"})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }